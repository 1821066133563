@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select:not([size]) {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: #4b5563;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #6b7280;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #6b7280;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

.toggle-bg:after {
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  background: #fff;
  border-width: 1px;
  border-color: #d1d5db;
  border-radius: 9999px;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  position: absolute;
  top: .125rem;
  left: .125rem;
}

input:checked + .toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  background: #1c64f2;
  border-color: #1c64f2;
}

.tooltip-arrow, .tooltip-arrow:before {
  width: 8px;
  height: 8px;
  background: inherit;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: #e5e7eb;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  width: 8px;
  height: 8px;
  background: inherit;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  width: 9px;
  height: 9px;
  background: inherit;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #4b5563;
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #4b5563;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

html {
  scroll-behavior: smooth;
  min-height: 100%;
  font-family: Montserrat, sans-serif;
}

body {
  min-height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-left-2 {
  left: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.w-1\/2 {
  width: 50%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-full {
  width: 100%;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-l {
  border-left-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity));
}

.border-grapeExpectations-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 60 85 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-t-gray-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bg-grapeExpectations-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 73 103 / var(--tw-bg-opacity));
}

.bg-grapeExpectations-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 60 85 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

section.default {
  padding-top: 5rem;
  padding-bottom: 4rem;
}

section.default h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
}

@media (width >= 768px) {
  section.default h2 {
    font-size: 3rem;
    line-height: 1;
  }
}

section.intro {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
  background-image: url("lines-bg.d8f69326.svg");
  padding-top: 5rem;
  padding-bottom: 5rem;
}

section.intro .card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(231 70 148 / var(--tw-border-opacity));
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .3;
  text-align: center;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 20px 6px;
  flex-direction: row;
  align-items: center;
  padding: 1.25rem;
  display: flex;
}

@media (width >= 768px) {
  section.intro .card {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

section.intro .card.intro {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-style: none;
  align-items: center;
  padding: 0;
}

section.intro .card.intro h3 {
  max-width: 42rem;
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(214 31 105 / var(--tw-border-opacity));
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .3;
  text-align: center;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 30px 60px 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  section.intro .card.intro h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 1024px) {
  section.intro .card.intro h3 {
    border-top-left-radius: 40px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}

section.intro .card.intro h3 {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

section.info {
  --tw-bg-opacity: 1;
  background-color: rgb(104 88 123 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
  padding-top: 7rem;
  padding-bottom: 7rem;
  position: relative;
}

section.info h2 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

section.info p.intro {
  max-width: 42rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

section.info:before {
  content: "";
  height: 5rem;
  width: 100%;
  background-image: url("info-bg-top.c5d93689.svg");
  background-position: top;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

section.info:after {
  content: "";
  height: 5rem;
  width: 100%;
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("info-bg-bottom.548dd52b.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

section.steps {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

section.more-info {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.main-navigation {
  z-index: 40;
  width: 100%;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
}

.main-navigation .navbar {
  background-color: #0000;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (width >= 1024px) {
  .main-navigation .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.main-navigation .navbar a, .main-navigation .navbar button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.main-navigation .navbar a:hover, .main-navigation .navbar button:hover {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.main-navigation .navbar .dropdown, .main-navigation .navbar .dropdown a:hover {
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.main-navigation .logo {
  max-width: 6rem;
}

@media (width >= 1024px) {
  .main-navigation .logo {
    max-width: 8rem;
  }
}

.main-navigation .logo svg {
  height: 100%;
  width: 100%;
}

.main-navigation .logo svg .fill {
  fill: #f3f4f6;
}

.main-navigation .logo svg .fill-reverse {
  fill: #1f2937;
}

.main-navigation .logo svg .stroke {
  fill: #f3f4f6;
}

.main-navigation .menu > :where(li:hover) > :where(ul) {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

@media (width >= 768px) {
  .main-navigation .menu > :where(li:hover) > :where(ul) {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
  }
}

@media (width >= 1024px) {
  .main-navigation .menu > :where(li:hover) > :where(ul) {
    --tw-bg-opacity: .2;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.main-navigation.scrolled {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.main-navigation.scrolled .navbar a, .main-navigation.scrolled .navbar button {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.main-navigation.scrolled .navbar a:hover, .main-navigation.scrolled .navbar button:hover {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.main-navigation.scrolled .navbar .dropdown {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.main-navigation.scrolled .navbar .dropdown a:hover {
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.main-navigation.scrolled .logo svg {
  height: 100%;
  width: 100%;
}

.main-navigation.scrolled .logo svg .fill {
  fill: #374151;
}

.main-navigation.scrolled .logo svg .fill-reverse {
  fill: #f3f4f6;
}

.main-navigation.scrolled .logo svg .stroke {
  fill: #374151;
}

.main-navigation.scrolled .menu > :where(li:hover) > :where(ul) {
  --tw-bg-opacity: 1;
  background-color: rgb(250 209 232 / var(--tw-bg-opacity));
}

.drawer-toggle:checked ~ .drawer-content {
  transform: none;
}

.drawer {
  z-index: 40;
  height: 100vh;
  width: 20rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  padding: 1rem;
  position: fixed;
  overflow-y: auto;
}

.drawer button.close {
  background-color: #0000;
  border-radius: .5rem;
  align-items: center;
  padding: .375rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: inline-flex;
  position: absolute;
  top: .625rem;
  right: .625rem;
}

.drawer button.close:hover {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.drawer .drawer--title {
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: inline-flex;
}

.drawer .drawer--link, .drawer .drawer--dropdown-button {
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.drawer .drawer--link:hover, .drawer .drawer--dropdown-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.drawer .drawer--dropdown-button {
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.drawer .drawer--dropdown .drawer--link {
  width: 100%;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.hero {
  min-height: 65vh;
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-blend-mode: multiply;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .hero {
    min-height: 60vh;
    background-position: 100%;
  }
}

@media (width >= 1024px) {
  .hero {
    background-size: contain;
  }
}

.hero .hero-overlay {
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .7;
  position: absolute;
}

.hero .hero-content {
  z-index: 1;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  justify-content: center;
  position: relative;
}

@media (width >= 768px) {
  .hero .hero-content {
    text-align: left;
    justify-content: flex-start;
  }
}

.hero .hero-content h1 {
  margin-bottom: 1.25rem;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1;
}

.hero.hero-home {
  background-image: url("hero-bg-home.8c0f7536.png");
}

.hero.hero-microblading {
  background-image: url("hero-bg.d2845cbf.png");
  background-position: center;
}

@media (width >= 768px) {
  .hero.hero-microblading {
    background-position: 100%;
  }
}

.hero.hero-microblading .hero-content h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

@media (width >= 640px) {
  .hero.hero-microblading .hero-content h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.hero.hero-micropigmentacion {
  background-image: url("hero-bg.81273555.png");
  background-position: center;
}

@media (width >= 768px) {
  .hero.hero-micropigmentacion {
    background-position: 100%;
  }
}

.hero.hero-micropigmentacion .hero-content h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 640px) {
  .hero.hero-micropigmentacion .hero-content h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .hero.hero-micropigmentacion .hero-content h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.hero.hero-extensiones {
  background-image: url("hero-bg.d2845cbf.png");
  background-position: center;
}

@media (width >= 768px) {
  .hero.hero-extensiones {
    background-position: 100%;
  }
}

.hero.hero-extensiones .hero-content h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 640px) {
  .hero.hero-extensiones .hero-content h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .hero.hero-extensiones .hero-content h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.hero.hero-peeling {
  background-image: url("hero-bg.ab69a2a3.png");
  background-position: center;
}

@media (width >= 768px) {
  .hero.hero-peeling {
    background-position: 100%;
  }
}

.hero.hero-peeling .hero-content h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 640px) {
  .hero.hero-peeling .hero-content h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .hero.hero-peeling .hero-content h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

a.btn, button.btn, [type="button"].btn, [type="reset"].btn, [type="submit"].btn {
  --tw-bg-opacity: 1;
  background-color: rgb(58 94 126 / var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
  border-radius: .5rem;
  align-items: center;
  padding: .625rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

a.btn:hover, button.btn:hover, [type="button"].btn:hover, [type="reset"].btn:hover, [type="submit"].btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 86 113 / var(--tw-bg-opacity));
}

a.btn.btn-primary, button.btn.btn-primary, [type="button"].btn.btn-primary, [type="reset"].btn.btn-primary, [type="submit"].btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(223 59 87 / var(--tw-bg-opacity));
}

a.btn.btn-primary:hover, button.btn.btn-primary:hover, [type="button"].btn.btn-primary:hover, [type="reset"].btn.btn-primary:hover, [type="submit"].btn.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(211 52 79 / var(--tw-bg-opacity));
}

a.btn.btn-secondary, button.btn.btn-secondary, [type="button"].btn.btn-secondary, [type="reset"].btn.btn-secondary, [type="submit"].btn.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(131 108 146 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

a.btn.btn-secondary:hover, button.btn.btn-secondary:hover, [type="button"].btn.btn-secondary:hover, [type="reset"].btn.btn-secondary:hover, [type="submit"].btn.btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(104 88 123 / var(--tw-bg-opacity));
}

.card {
  height: 100%;
  max-width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  margin: auto;
  display: flex;
}

.card .image-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.card .image-wrapper img {
  width: 100%;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.card .card-content {
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  display: flex;
}

.card .card-content h3 {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.card .card-content p {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.card .card-content .card-content--footer {
  width: 100%;
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1.5rem;
  display: flex;
}

.card .card-content .card-content--footer .btn {
  font-size: .875rem;
  line-height: 1.25rem;
}

section.contact {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-image: url("stars-bg.777329be.svg");
  background-position: top;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

section.contact:before {
  content: "";
  height: 5rem;
  width: 100%;
  background-image: url("contact-bg-top.313ba3e2.svg");
  background-position: top;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

section.contact .card {
  height: 100%;
  width: 100%;
  background-color: rgb(82 73 103 / var(--tw-bg-opacity));
  --tw-bg-opacity: .6;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border: 1px solid #524967b3;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem;
  display: flex;
}

section.contact .card p {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

section.contact .card p:last-child {
  margin-bottom: 0;
}

@media (width >= 768px) {
  section.contact .card p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.microblading section.steps .step-number {
  height: 2.5rem;
  width: 2.5rem;
  --tw-translate-y: -50%;
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(191 18 93 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 50%;
}

.microblading section.steps .step {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  gap: 0;
  display: grid;
  position: relative;
}

@media (width >= 768px) {
  .microblading section.steps .step {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    padding: 2rem;
  }

  .microblading section.steps .step.reverse img {
    order: 9999;
  }
}

.microblading section.steps .step img {
  width: 100%;
  border-radius: .5rem;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .microblading section.steps .step img {
    width: 400px;
  }
}

.microblading section.steps .step .content {
  padding: 2rem;
}

@media (width >= 768px) {
  .microblading section.steps .step .content {
    grid-column: span 2 / span 2;
    padding: 0;
  }
}

.microblading section.steps .step .content p {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  margin-bottom: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.microblading section.steps .step .content p:last-of-type {
  margin-bottom: 0;
}

@media (width >= 1024px) {
  .microblading section.steps .step .content p {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.microblading section.info .rostro {
  flex-direction: column;
  align-items: flex-start;
  gap: .75rem;
  margin-top: 2.5rem;
  display: flex;
}

@media (width >= 640px) {
  .microblading section.info .rostro {
    flex-direction: row;
    gap: 1.5rem;
  }

  .microblading section.info .rostro:first-of-type {
    margin-top: 0;
  }
}

@media (width >= 1280px) {
  .microblading section.info .rostro {
    align-items: center;
  }
}

.microblading section.info .rostro img {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.microblading section.info .rostro .content {
  text-align: center;
}

@media (width >= 640px) {
  .microblading section.info .rostro .content {
    text-align: left;
  }
}

.microblading section.info .rostro .content h4 {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.microblading section.info .rostro .content p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.micropigmentacion section.info ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.micropigmentacion section.info ol > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

@media (width >= 768px) {
  .micropigmentacion section.info ol {
    text-align: justify;
  }
}

.micropigmentacion section.info ol li {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  margin-bottom: .5rem;
}

.micropigmentacion section.info .tipo {
  max-width: 24rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  text-align: center;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem;
}

.micropigmentacion section.info .tipo p {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.micropigmentacion section.info:after, .micropigmentacion section.contact:before {
  background-image: none;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

:is(.dark .dark\:border-blue-500) {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-transparent) {
  border-color: #0000;
}

:is(.dark .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800\/50) {
  background-color: #1f293780;
}

:is(.dark .dark\:bg-opacity-80) {
  --tw-bg-opacity: .8;
}

:is(.dark .dark\:text-blue-500) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:bg-blue-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-blue-500:hover) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-gray-300:hover) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }
}

@media (width >= 768px) {
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=index.db525531.css.map */
