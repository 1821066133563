.microblading {
  section.steps {
    .step-number {
      @apply absolute
      inline-flex
      items-center
      justify-center
      w-10
      h-10
      text-lg
      font-bold
      text-white
      bg-pink-700
      border-2
      border-rose-50
      rounded-full
      -translate-y-1/2
      translate-x-1/2
      right-1/2
      top-0;
    }

    .step {
      @apply relative
        grid
        grid-cols-1
        md:grid-cols-3
        gap-0
        md:gap-8
        items-center
        justify-center
        bg-rose-50
        shadow-lg
        md:py-8
        md:px-8

        rounded-lg;

      &.reverse {
        img {
          @apply md:order-last;
        }
      }

      img {
        @apply w-full
          rounded-lg
          md:w-[400px]
          mx-auto;
      }

      .content {
        @apply md:col-span-2
        p-8
        md:p-0;

        p {
          @apply text-base
          lg:text-lg
          text-gray-700
          mb-3
          last-of-type:mb-0;
        }
      }
    }
  }

  section.info {
    .rostro {
      @apply flex
        flex-col
        sm:flex-row
        gap-3
        sm:gap-6
        items-start
        xl:items-center
        mt-10
        sm:first-of-type:mt-0;

      img {
        @apply max-w-[150px]
          mx-auto;
      }

      .content {
        @apply text-center
          sm:text-left;

        h4 {
          @apply text-xl
            font-semibold
            mb-3;
        }

        p {
          @apply text-base;
        }
      }
    }
  }
}
