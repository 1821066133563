.hero {
  @apply min-h-[65vh]
    md:min-h-[60vh]
    bg-no-repeat
    bg-left
    bg-stone-600
    bg-blend-multiply
    md:bg-right
    bg-cover
    lg:bg-contain
    relative
    flex
    items-center
    text-white;

  .hero-overlay {
    @apply bg-black
      bg-opacity-70
      absolute
      w-full
      h-full;
  }

  .hero-content {
    @apply relative
      z-[1]
      text-center
      text-white
      justify-center
      md:text-left
      md:justify-start;

    h1 {
      @apply mb-5 text-6xl font-normal;
    }
  }

  &.hero-home {
    @apply bg-[url(../img/hero-bg-home.png)];
  }

  &.hero-microblading {
    @apply bg-[url(../img/microblading/hero-bg.png)]
      bg-center
      md:bg-right;

    .hero-content {
      h1 {
        @apply text-4xl sm:text-6xl;
      }
    }
  }

  &.hero-micropigmentacion {
    @apply bg-[url(../img/micropigmentacion/hero-bg.png)]
      bg-center
      md:bg-right;

    .hero-content {
      h1 {
        @apply text-3xl sm:text-5xl md:text-6xl;
      }
    }
  }

  &.hero-extensiones {
    @apply bg-[url(../img/extensiones/hero-bg.png)]
      bg-center
      md:bg-right;

    .hero-content {
      h1 {
        @apply text-3xl sm:text-5xl md:text-6xl;
      }
    }
  }

  &.hero-peeling {
    @apply bg-[url(../img/peeling/hero-bg.png)]
      bg-center
      md:bg-right;

    .hero-content {
      h1 {
        @apply text-3xl sm:text-5xl md:text-6xl;
      }
    }
  }
}
